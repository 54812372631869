import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Loader from '../Loader/Loader';
import Navbar from '../../component/Navbar/navbar.js'
import Footer from '../../component/Footer/footer.js';
import CookieModal from '../../component/cookieModal.js';
import "../../../node_modules/react-modal-video/css/modal-video.css";
import { product_category } from '../../axios/api/product_category.js';
import TinySlider from 'tiny-slider-react';
import "tiny-slider/dist/tiny-slider.css";
import Cookies from "universal-cookie";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";


import {baseurl, apikey, domain } from '../../baseurl';


import axios from 'axios';

import SimpleImageSlider from "react-simple-image-slider";


import { useSelector, useDispatch } from 'react-redux';

const settings = {
    container: '.tiny-single-item',
    items: 2,
    controls: false,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    speed: 400,
    gutter: 16,
}

const settingsBanner = {
    container: '.tiny-single-item',
    items: 1,
    controls: false,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    speed: 400,
    gutter: 16,
}



export default function IndexLandingFour() {
    const [getRes, setGetRes] = useState(null);
    const [loading, setLoading] = useState(true);
    const response = useSelector((state) => state.websiteReducer);
    const [sectionsArray, setSectionsArray] = useState();
    const [webData, setWebData] = useState();
    const cookie = new Cookies();
    const [siteData, setSiteData] = useState();
    const dispatch = useDispatch();

    const [imgBanners,setImagesBanner] = useState([]);



      useEffect(() => {


        banner();

        if (response && response.data && response.data.data) {

            console.log(response);
            setWebData(response.data.data);
          setSectionsArray(response.data.data.home_sections);
          setLoading(false); // Set loading to false when data is fetched
        }

        if(cookie.get('icp_favicon'))
        {
             setSiteData(cookie.get("icp_favicon"));
        }


      }, [response]);


      useEffect(() => {

        const res = async ()=>{
            const result = await product_category();
            //console.log(result);

                if(result.status === 200)
                {
                    if(result.data.status === 1)
                    {

                        setGetRes(result.data.data);
                       // console.log(result.data.data);

                    }
                }
                else
                {
                    if(result.response.data.status === 0)
                    {
                       // let msg = result.response.data.message;

                       // console.log(msg);


                    }
                }
        }

        res();
        //console.log(webData);

      }, []);




      const banner = ()=>{

        let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: baseurl+'/banners',
        headers: {
            'apikey': apikey,
            'domain': domain
        }
        };

        axios.request(config)
        .then((response) => {

            if(response.data.status == '1')
            {
                setImagesBanner(response.data.data);
            }
        })
        .catch((error) => {
        console.log(error);
        });

  }





      if (loading) {
        return (
            <Loader />

          );
      }




    const Category = ({data})=>{
        const design = (
            <>
                 <Link to={"/category/"+data.slug}><div className="group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 text-center transition-all duration-500 ease-in-out">
                            <div className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                                  <img src={data ? data.image :'images/course/icon1.png'} alt="" className='appIcon'/>
                            </div>

                            <div className="content mt-7">
                                <Link to={"/category/"+data.slug} className="title h5 text-lg font-medium hover:text-indigo-600">{data.name}</Link>
                                <p className="text-slate-400 mt-3"></p>

                                {/* <div className="mt-5">
                                    <Link to="#" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More <div className="ms-2 text-[10px]"/></Link>
                                </div> */}
                            </div>
                        </div>
                     </Link>
            </>
        );

        return design;
    }

    const AboutUs = React.memo(()=>{
        const design = (
            <>
                <section className="relative pt-2 pb-24  dark:bg-slate-800 mt-7">
                    <div className="container relative md:mt-24 mt-16">
                        <div className="grid md:grid-cols-2 grid-cols-1 items-center mt-16 gap-[30px]">
                            <div className="relative">
                                <div className="md:me-10">
                                    <img src='images/course/aboutus1.png' className="" alt="" />
                                    <h5 className="mb-6 md:text-2xl md:leading-normal text-2xl text-center leading-normal font-semibold">Discover Sri Bharadwaj and Associates advantage today!</h5>

                                </div>

                            </div>

                            <div className="lg:ms-8 mt-8 md:mt-0">
                                <h4 className="mb-4 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">About Sri Bharadwaj and Associates  </h4>
                                <p className="text-slate-400 text-justify">{webData ? webData.home_about_us: ''}</p>

                            </div>
                        </div>
                    </div>
                </section>
            </>
        );

        return design;
    })

    const PopularCourses = React.memo(()=>{
        return (
            <>
                <section className="relative md:py-24 py-16 overflow-hidden" id="courses">
                    <div className="container relative">
                        <div className="grid grid-cols-1 pb-8 text-center">
                            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Popular Courses</h3>
                            <p className="text-slate-400 max-w-xl mx-auto">Explore Our Comprehensive CA Exam Preparation Programs"</p>
                        </div>

                        <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 mt-8 gap-[30px]">

                            { webData ?
                                webData.popular_courses.map((item,index)=>{
                                    return (
                                        <div key={index} className="group relative rounded-md shadow hover:shadow-lg dark:shadow-gray-800 duration-500 ease-in-out overflow-hidden">

                                            <Link to={"/course/"+item.slug}>
                                                <div className="relative overflow-hidden">
                                                    <img src={item.image} className="group-hover:scale-110 duration-500 ease-in-out" alt="course_img"/>
                                                    <div className="absolute inset-0 bg-slate-900/50 opacity-0 group-hover:opacity-100 duration-500 ease-in-out"></div>

                                                    <div className="absolute start-0 bottom-0 opacity-0 group-hover:opacity-100 duration-500 ease-in-out">
                                                        <div className="pb-4 ps-4 flex items-center">

                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                            <div className="content p-6 relative">
                                            <Link to={"/course/"+item.slug} className="font-medium block text-indigo-600">{item.main_category}</Link>
                                                <Link to={"/course/"+item.slug} className="text-lg font-medium block hover:text-indigo-600 duration-500 ease-in-out mt-2"> {item.product_name}</Link>
                                                <Link to={"/course/"+item.slug} type="submit" id="submitsubscribe" name="send" className=" mt-5 py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Buy now</Link>
                                                <div className="absolute -top-7 end-6 z-1 price_btn group-hover:opacity-100 duration-500 ease-in-out d-block"  >
                                                    {/*<div className="flex justify-center items-center size-14 bg-white dark:bg-slate-900 rounded-full shadow-lg dark:shadow-gray-800 text-indigo-600 dark:text-white" >

                                                    </div>*/}
                                                </div>
                                            </div>

                                        </div>
                                    )
                                })
                                : null
                            }

                        </div>

                    </div>
                </section>
            </>
        );
    })



    const PopularBooks = React.memo(()=>{
        return (
            <>
                <section className="relative md:py-24 py-16 overflow-hidden" id="books">
                    <div className="container relative">
                        <div className="grid grid-cols-1 pb-8 text-center">
                            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Popular Books</h3>
                            <p className="text-slate-400 max-w-xl mx-auto">Explore Our Comprehensive CA Exam Preparation Programs"</p>
                        </div>

                        <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 mt-8 gap-[30px]">

                            { webData ?
                                webData.popular_books.map((item,index)=>{
                                    return (
                                        <div key={index} className="group relative rounded-md shadow hover:shadow-lg dark:shadow-gray-800 duration-500 ease-in-out overflow-hidden">

                                            <Link to={"/course/"+item.slug}>
                                                <div className="relative overflow-hidden">
                                                    <img src={item.image} className="group-hover:scale-110 duration-500 ease-in-out" alt="course_img"/>
                                                    <div className="absolute inset-0 bg-slate-900/50 opacity-0 group-hover:opacity-100 duration-500 ease-in-out"></div>

                                                    <div className="absolute start-0 bottom-0 opacity-0 group-hover:opacity-100 duration-500 ease-in-out">
                                                        <div className="pb-4 ps-4 flex items-center">

                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                            <div className="content p-6 relative">
                                            <Link to={"/course/"+item.slug} className="font-medium block text-indigo-600">{item.main_category}</Link>
                                                <Link to={"/course/"+item.slug} className="text-lg font-medium block hover:text-indigo-600 duration-500 ease-in-out mt-2"> {item.product_name}</Link>
                                                <Link to={"/course/"+item.slug} type="submit" id="submitsubscribe" name="send" className=" mt-5 py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Buy now</Link>
                                                <div className="absolute -top-7 end-6 z-1 price_btn group-hover:opacity-100 duration-500 ease-in-out d-block"  >
                                                    {/*<div className="flex justify-center items-center size-14 bg-white dark:bg-slate-900 rounded-full shadow-lg dark:shadow-gray-800 text-indigo-600 dark:text-white" >

                                                    </div>*/}
                                                </div>
                                            </div>

                                        </div>
                                    )
                                })
                                : null
                            }

                        </div>

                    </div>
                </section>
            </>
        );
    })


    const Testimonial = React.memo(()=>{
        return (
            <>
                <section className="relative md:py-24 py-16 overflow-hidden">
                    <div className="container relative">
                        <div className="grid grid-cols-1 pb-8 text-center">
                            <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Our Community</h3>

                            <p className="text-slate-400 max-w-xl mx-auto">See What Our Students Have to Say!!</p>
                        </div>
                        <div className="flex justify-center">
                            <div className="grid grid-cols-2 lg:grid-cols-1 md:grid-cols-1 mt-8 gap-[30px] ">


                                        <TinySlider settings={settings}>
                                        {

                                            webData ? webData.testimonials.map((item, index) => {
                                                return (
                                                    <div className="tiny-slide tiny-single-item" key={index}>
                                                        <div className="grid gird-cols-1 p-6 lg:p-0 relative rounded-md shadow dark:shadow-gray-800 overflow-hidden m-2">
                                                            <div className="flex justify-start items-center gap-4 p-6">
                                                                <div>
                                                                    <img className="lg:rounded-full rounded-full mx-auto h-24 w-24" src={item.image ? item.image : ''} alt="student" />
                                                                </div>
                                                                <div>
                                                                    <span className="text-indigo-600 block mb-1">{item.username}</span>
                                                                    <span className="text-slate-400 text-sm dark:text-white/60 block">{item.designation}</span>
                                                                </div>
                                                            </div>
                                                            <div className="pt-6 lg:p-6 text-center lg:text-start space-y-4">
                                                                <p className="text-base text-slate-400">{item.text}</p>

                                                                {/* Display rating with colored hearts */}
                                                                <div className="rating">
                                                                    {Array.from({ length: 5 }, (v, i) => (
                                                                        <span key={i} style={{ color: i < item.rating ? '#ff0000' : '#cccccc' }}>
                                                                            &#10084; {/* Heart icon */}
                                                                        </span>
                                                                    ))}
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            : null
                                        }
                                        </TinySlider>


                            </div>
                        </div>


                    </div>
                </section>
            </>
        );
    })

    const Instructor = React.memo(()=>{
        return (
            <>
                <div className="container relative md:mt-24 mt-16 mb-12" id="Instructors">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Our Instructor</h3>

                        <p className="text-slate-400 max-w-xl mx-auto">Meet Our Experienced Faculty Dedicated to Your Success</p>
                    </div>

                    <div className="grid md:grid-cols-12 grid-cols-1 mt-8 gap-[30px] our-instructor">
                        <div className="lg:col-span-3  m-auto justify-content-center">
                            <div className="group text-center">
                                <div className="relative inline-block mx-auto size-52 rounded-full overflow-hidden">
                                    <img src="images/instructor/123.jpeg" className="" alt=""/>


                                    <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black size-52 rounded-full opacity-0 group-hover:opacity-100 duration-500"></div>
                                </div>

                                <div className="content mt-3">
                                    <a href="/" className="text-lg font-semibold hover:text-indigo-600 duration-500"> CA Raghuram Sarma JV</a>
                                    <p className="text-slate-400">Faculty</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    })


    return (
        <>
            {/* <Navbar /> */}
         <Navbar navClass="nav-sticky" />

            <section className="relative table w-full pt-24 pb-24 bg-gray-50 dark:bg-slate-800 " id="home">




            <div className="flex justify-center">
                            <div className="grid grid-cols-2 lg:grid-cols-1 md:grid-cols-1 mt-8 gap-[30px] ">


                                        <TinySlider settings={settingsBanner}>
                                        {

                                    imgBanners ? imgBanners.map((item, index) => {
                                                return (
                                                    <div className="tiny-slide tiny-single-item" key={index}>

                                                        <img src={item ? item : ''} />

                                                    </div>
                                                )
                                            })
                                            : null
                                        }
                                        </TinySlider>


                            </div>
                        </div>



                <div className="container relative">
                    <div className="grid lg:grid-cols-12 md:grid-cols-2 mt-10 items-center gap-[30px]">
                        <div className="lg:col-span-7">
                            {/* <span className="bg-indigo-600/5 text-indigo-600 text-xs font-bold px-2.5 py-0.5 rounded h-5">Form Landing Page</span> */}

                            {/* <h4 className="font-semibold lg:leading-normal leading-normal text-4xl lg:text-5xl mt-2 mb-5">Cutting-Edge POS <br/>Software for, </h4> */}
                            <h1 className="font-bold lg:leading-normal heading leading-normal text-5xl lg:text-5xl mb-5">{webData ? webData.banner_heading: ''}                     </h1>

                            <p className="text-slate-400 text-lg max-w-xl text-justify width90">{webData ? webData.banner_description: ''}</p>

                            <div className="relative mt-10">
                                {/* <Link to="/get-started" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-full me-1">Get Started</Link> */}
                                {/* <Link className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-full me-5 ms-4"> Support Training</Link> */}
                                {/* <Link to="/get-started"  className="py-2 px-5  inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Get Started</Link> */}
                            </div>
                        </div>


                        <div className="md:col-span-5">
                            <div className="relative">
                                <img src='images/course/banner1.png' alt="" />
                            </div>
                        </div>

                        <div className="overflow-hidden after:content-[''] after:absolute after:size-32 after:bg-red-600/5 after:top-10 after:start-0 after:-z-1 after:rounded-3xl after:animate-[spin_10s_linear_infinite]"></div>
                        <div className="overflow-hidden after:content-[''] after:absolute after:size-[512px] after:bg-indigo-600/5 after:top-0 after:-end-5 after:-z-1 after:rounded-full"></div>
                    </div>
                </div>
            </section>



            { sectionsArray ? sectionsArray.includes('aboutus') && (
                <AboutUs />
            ) : ''}


            <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        {/* <h6 className="text-indigo-600 text-sm font-bold uppercase mb-2">Features</h6> */}
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Browse Online Course Categories </h3>

                        <p className="text-slate-400  mx-auto">Search your future opportunity with our categories</p>
                    </div>
                </div>

                    <div className="container relative">
                        <div className="grid md:grid-cols-4 grid-cols-1 mt-8 gap-[30px]">
                        {
                            getRes ? getRes.map((item,index)=>{
                                return <Category data={item} key={index} />
                            }) : null
                        }


                        </div>
                    </div>
            </section>


            { sectionsArray ? sectionsArray.includes('popular_courses') && (
                <PopularCourses />

            ) : ''}


            <PopularBooks />




            { sectionsArray ? sectionsArray.includes('testimonials') && (
                <Testimonial />
            ) : ''}




            { sectionsArray ? sectionsArray.includes('instructor') && (
                <Instructor />
            ) : ''}




            <Footer />
            <CookieModal />
        </>
    )
}
