
const baseurl = "https://encryption.edukrypt.app/lmsapi";
const apikey = "1a973f53cfa911ca8a4cc5e7b235ab4d";
const domain = "https://sbaedu.com";



// const baseurl = "http://localhost/saas_encryption/lmsapi";
// const apikey = "1a973f53cfa911ca8a4cc5e7b235ab4d";
// const domain = "https://sbaedu.com";





export {
    baseurl,
    apikey,
    domain
} ;